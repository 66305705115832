module.exports = [{
    filename: 'index.html',
    template: 'page-list.html',
    chunks: ['vendor', 'pageList']
  },
  /*  {
      filename: 'Pages/mynh/owners/index.html',
      template: 'home/home.html',
      chunks: ['vendor', 'nh']
    }, */
  {
    filename: 'Pages/mynh/owners/dashboard.html',
    template: 'dashboard/dashboard.html',
    chunks: ['vendor', 'nh']
  }, {
    filename: 'Pages/mynh/owners/product-list.html',
    template: 'products/product-list.html',
    chunks: ['vendor', 'nh']
  },
  {
    filename: 'Pages/mynh/owners/equipment-list.html',
    template: 'equipment/equipment-list.html',
    chunks: ['vendor', 'nh']
  },
  {
    filename: 'Pages/mynh/owners/register-product.html',
    template: 'register-product/register-product.html',
    chunks: ['vendor', 'nh']
  },
  {
    filename: 'Pages/mynh/owners/associate-dealer.html',
    template: 'associate-dealer/associate-dealer.html',
    chunks: ['vendor', 'nh']
  },
  {
    filename: 'Pages/mynh/owners/link-products-equipment.html',
    template: 'link-products-equipment/link-products-equipment.html',
    chunks: ['vendor', 'nh']
  },
  {
    filename: 'Pages/mynh/owners/link-products-plm.html',
    template: 'link-products-plm/link-products-plm.html',
    chunks: ['vendor', 'nh']
  },
  {
    filename: 'Pages/mynh/owners/product-registration-feedback.html',
    template: 'product-registration-feedback/product-registration-feedback.html',
    chunks: ['vendor', 'nh']
  }
];
