import pages from '../conf/pages';

pages
  .filter(page => page.filename !== 'index.html')
  .reduce((duplicatedPages, page) => {
    // Duplicates all the pages that have query string variants
    page.href = `/${page.filename}`;
    duplicatedPages.push(page);

    if (page.indexQueryString) {
      return duplicatedPages.concat(page.indexQueryString.map(queryString => {
        return Object.assign({}, page, {
          href: `/${page.filename}?${queryString}`
        });
      }))
    }

    return duplicatedPages;
  }, [])
  .forEach(page => {
    const item = document.createElement('li');


    let clampLink = page.href.substr(1).substring(page.href.substr(1).lastIndexOf("owners/") + 7);


    item.innerHTML = `<a href="${page.href}">${clampLink}</a>`;
    document.querySelector('body > ol')
      .appendChild(item);
  });
